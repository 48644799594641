import blockSettings from "@/blocks";

const LinkingEvents = {
		data() {
				return {
						linking: false,
						tempLink: null,
						linkStartData: null
				}
		},
		computed: {
				//TODO: Убрать это в lines?
				links: {
						get: function() {
								let links = []
								this.blocks.forEach(b => {
										b.slots.forEach(s => {
												if (s.toID) {
														let originSlot = this.getSlotByName(b.outputs, s.name, true)
														let targetSlot = this.getSlotByName(this.blocks.find(b => b.id === s.toID).inputs, s.toName, true)
														links.push({
																id: originSlot + '_' + targetSlot + '_' + s.blockID + '_' + s.toID,
																targetName: s.toName,
																originName: s.name,
																originID: s.blockID,
																originSlot,
																targetID: s.toID,
																targetSlot
														})
												}
										})
								})
								return links
						}
				},
				lines() {
						let lines = []

						for (let link of this.links) {

								let originBlock = this.blocks.find(block => {
										return block.id === link.originID
								})

								let targetBlock = this.blocks.find(block => {
										return block.id === link.targetID
								})

								if (!originBlock || !targetBlock) {
										console.log('Remove invalid link', link)
										this.removeLink(link.id)
										continue
								}

								if (originBlock.id === targetBlock.id) {
										console.log('Loop detected, remove link', link)
										this.removeLink(link.id)
										continue
								}

								//определение по имени, а не по номеру
								let originLinkPos = this.getConnectionPos(originBlock, link.originSlot, false)
								let targetLinkPos = this.getConnectionPos(targetBlock, link.targetSlot, true)

								if (!originLinkPos || !targetLinkPos) {
										console.log('Remove invalid link (slot not exist)', link)
										this.removeLink(link.id)
										continue
								}

								let x1 = originLinkPos.x
								let y1 = originLinkPos.y

								let x2 = targetLinkPos.x
								let y2 = targetLinkPos.y

								let settings = blockSettings[originBlock.family];

								lines.push({
										x1: x1,
										y1: y1,
										x2: x2,
										y2: y2,
										style: {
												stroke: settings ? settings.arrowColor : '#c587fc',
												strokeWidth: 2 * this.scale,
												fill: 'none'
										},
										outlineStyle: {
												stroke: '#666',
												strokeWidth: 4 * this.scale,
												strokeOpacity: 0.6,
												fill: 'none'
										}
								})
						}

						if (this.tempLink) {
								// eslint-disable-next-line vue/no-side-effects-in-computed-properties
								this.tempLink.style = {
										stroke: '#8f8f8f',
										strokeWidth: 4 * this.scale,
										fill: 'none'
								}

								lines.push(this.tempLink)
						}

						return lines
				}
		},
		methods: {
				getConnectionPos(block, slotNumber, isInput) {
						if (!block || slotNumber === -1) {
								return undefined
						}

						let x = 0
						let y = 0

						x += block.x
						y += block.y

						y += this.blockOptions.titleHeight

						// eslint-disable-next-line no-empty
						if (isInput && block.inputs.length > slotNumber) {
						} else if (!isInput && block.outputs.length > slotNumber) {
								x += this.blockOptions.width
						} else {
								console.error('slot ' + slotNumber + ' not found, is input: ' + isInput, block)
								return undefined
						}

						// (height / 2 + blockBorder + padding)
						y += (24 / 2)
						//  + (height * slotNumber)
						if (!isInput)
								y += (32 * (slotNumber + block.inputs.length) + 3)
						else
								y += 34 * slotNumber


						x *= this.scale
						y *= this.scale

						x += this.centerX
						y += this.centerY

						return {x: x, y: y}
				},
				//Link check types

				/**
					* Проверка совместимости слотов по типу
					* @param {String|Object} typesOfOut Строка или массив типов исходящего слота. Типы в строке разделены символом |
					* @param {String|Object} typesOfIn Строка или массив типов входящего слота. Типы в строке разделены символом |
					*/
				slotsCompatibility(typesOfOut, typesOfIn) {
						typesOfIn = typeof typesOfIn === 'object' ? typesOfIn : typesOfIn.split('|')
						typesOfOut = typeof typesOfOut === 'object' ? typesOfOut : typesOfOut.split('|')

						let compatibility = true
						typesOfOut.forEach(type => {
								if (!typesOfIn.includes(type) && !typesOfIn.includes('mixed') && type !== 'mixed') {
										compatibility = false
								}
						})

						return compatibility
				},
				// Linking
				linkingStart(block, slotNumber) {
						this.linkStartData = {block: block, slotNumber: slotNumber}
						let linkStartPos = this.getConnectionPos(this.linkStartData.block, this.linkStartData.slotNumber, false)
						this.tempLink = {
								x1: linkStartPos.x,
								y1: linkStartPos.y,
								x2: this.mouseX,
								y2: this.mouseY
						}
						this.linking = true
				},
				/**
					*
					* @param targetBlock {Object}
					* @param targetBlock.slots {Array}
					* @param targetBlock.inputs {Array}
					* @param targetBlock.id {Number}
					* @param slotNumber {Number}
					*/
				linkingStop(targetBlock, slotNumber) {
						if(!this.linkStartData) return
						//Проверяем слоты на совместимость
						let comp = this.slotsCompatibility(this.linkStartData.block.outputs[this.linkStartData.slotNumber].type, targetBlock.inputs[slotNumber].type)

						let targetSlots = targetBlock.slots
						let originSlots = this.linkStartData.block.slots

						if (comp && this.linkStartData && targetBlock && slotNumber > -1) {
								//удаляем существующие линки
								let checkSlot = this.getSlotByName(targetBlock.slots, targetBlock.inputs[slotNumber].name)
								if(checkSlot) this.removeLink(this.links.find(l => l.targetName === checkSlot.name).id)

								// skip if looping
								if (this.linkStartData.block.id !== targetBlock.id) {

										let dataPush = {
												name: targetBlock.inputs[slotNumber].name,
												fromName: this.linkStartData.block.outputs[this.linkStartData.slotNumber].name,
												blockID: targetBlock.id,
												//slotID: slotNumber,
												fromID: this.linkStartData.block.id,
												//fromSlot: this.linkStartData.slotNumber
										}

										//если слот уже был указан, заменяем. Иначе добавляем
										targetSlots.push(dataPush)

										dataPush = {
												name: this.linkStartData.block.outputs[this.linkStartData.slotNumber].name,
												toName: targetBlock.inputs[slotNumber].name,
												blockID: this.linkStartData.block.id,
												//slotID: this.linkStartData.slotNumber,
												toID: targetBlock.id,
												//toSlot: slotNumber
										}

										//удаляем значение инпута, если оно было, записываем его во временные значения
										targetBlock.inputs[slotNumber].tempValue = targetBlock.inputs[slotNumber].value
										targetBlock.inputs[slotNumber].value = null

										//если слот уже был указан, заменяем. Иначе добавляем
										originSlots.push(dataPush)
								}

						} else {
								this.$notify({
										title: 'Слоты блоков имеют разные типы данных',
										type: 'error'
								})
						}

						this.linking = false
						this.tempLink = null
						this.linkStartData = null
				},
				linkingBreak(targetBlock, slotNumber) {
						let targetSlots = targetBlock.slots
						let name = targetBlock.inputs[slotNumber].name

						if (targetBlock && slotNumber > -1) {
								let findSlot = this.getSlotByName(targetSlots, name)
								const targetSlotIndex = this.getSlotByName(targetSlots, name, true)
								//console.log(findSlot, targetSlotIndex)
								if(!findSlot || targetSlotIndex < 0) return

								let originBlock = this.blocks.find(b => b.id === findSlot.fromID)
								//console.log(originBlock)

								if(!originBlock) return;
								const originBlockIndex = originBlock.slots.findIndex(s => {
										return s.toID === targetBlock.id && s.toName === name
								})
								//console.log(originBlockIndex)

								if(originBlockIndex < 0) return

								targetSlots.splice(targetSlotIndex, 1)
								originBlock.slots.splice(originBlockIndex, 1)

								//возвращаем временное значение, если оно было указано
								targetBlock.inputs[slotNumber].value = targetBlock.inputs[slotNumber].tempValue ?? null
								targetBlock.inputs[slotNumber].tempValue = null
						}
				},
				removeLink(linkID) {
						let link = undefined

						link = this.links.find(l =>  l.id === linkID )

						let targetBlock = this.blocks.find(b => (b.id === link.targetID))
						let originBlock = this.blocks.find(b => (b.id === link.originID))

						const targetSlotIndex = this.getSlotByName(targetBlock.slots, link.targetName, true)
						targetBlock.slots.splice(targetSlotIndex, 1)

						const originBlockIndex = originBlock.slots.findIndex(s => {
								return s.toID === targetBlock.id && s.toName === link.targetName
						})
						originBlock.slots.splice(originBlockIndex, 1)

				},
				/**
					* Возвращает слот или его индекс по имени
					* @param slots
					* @param slotName
					* @param getIndex
					* @returns {*}
					*/
				getSlotByName(slots, slotName, getIndex = false){
						return getIndex ? slots.findIndex(s => s.name === slotName) : slots.find(s => s.name === slotName)
				}
		}
}

export default LinkingEvents

<template>
  <div class="flex shadow-lg rounded-2xl">
    <!--   Color Block   -->
    <div class="h-28 w-12 rounded-l-2xl" :style="{ backgroundColor: color() }"></div>
    <div class="w-72 rounded-r-2xl bg-white py-2 px-3 relative">
      <!--   Animate status pointer   -->
      <div class="absolute w-4 h-4 -right-0.5 -top-0.5 rounded-full flex">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
              v-if="active"
              :class="{ 'bg-green-400' : errorsCount < 1, 'bg-yellow-400' : errorsCount >= 1 }"
        ></span>
        <span class="relative inline-flex rounded-full w-4 h-4"
              :class="{'bg-green-400': active && errorsCount < 1,
                       'bg-gray-300': !active && errorsCount < 1,
                       'bg-yellow-500' : errorsCount >= 1 }"></span>
      </div>
      <!--   Text block   -->
      <div class="text-left flex flex-wrap content-between h-full">
        <!--   Header   -->
        <div>
          <h4 class="text-xl font-bold text-left block">{{ name }}</h4>
          <p class="text-gray-500 text-xs">{{ requests !== undefined ? 'Requests: ' + requests : null }}</p>
        </div>
        <!--   Button + counter   -->
        <div class="flex items-center justify-between w-full">
          <button @click="$emit('clickOpen')">Open</button>
          <div class="text-gray-400 font-medium text-sm" v-if="blueprintsCount">{{ blueprintsCount }} blueprints</div>
          <!-- Small buttons -->

          <!-- Database button -->
          <div class="flex space-x-3 items-center">
            <div class="h-4 w-4 transition-opacity hover:opacity-50 cursor-pointer"
                 @click="$emit('databaseClick')"
                 v-if="showDatabaseIcon">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
              </svg>
            </div>
            <!-- Copy button -->
            <div class="transition-opacity hover:opacity-50 cursor-pointer"
                 @click="$emit('copyClick')"
                 v-if="showCopyIcon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
            </div>
            <!-- Delete button -->
            <div class="transition-opacity hover:opacity-50 cursor-pointer"
                 @click="$emit('deleteClick')"
                 v-if="showDeleteIcon">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </div>
            <!-- Errors button -->
            <div class="transition-opacity hover:opacity-50 cursor-pointer"
                 @click="$emit('errorsClick')"
                 v-if="errorsCount">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-red-600 animate-bounce inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span class="text-xs ml-2 text-red-600">{{ errorsCount }}</span>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    token: {
      default: '54c16123'
    },
    blueprintsCount: {
      default: null,
    },
    name: {
      default: null
    },
    active: {
      default: false
    },
    showDatabaseIcon: {
      type: Boolean,
      default: false
    },
    showCopyIcon: {
      type: Boolean,
      default: false
    },
    showDeleteIcon: {
      type: Boolean,
      default: false
    },
    requests: {
      type: Number,
      default: undefined
    },
    errorsCount: {
      type: Number,
      default: undefined
    }
  },

  methods: {
    color() {
      return '#' + this.intToRGB(this.hashCode(this.token))
    },
    hashCode(str) { // java String#hashCode
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    intToRGB(i) {
      let c = (i & 0x00FFFFFF)
          .toString(16)
          .toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    }
  }
}
</script>

<style scoped lang="scss">
 button {
   @apply bg-gradient-to-t from-pink-600 to-pink-500 shadow-md tracking-widest;
   @apply focus-within:outline-none focus:ring ring-pink-600 ring-1;
   @apply transition-all hover:scale-125;
   @apply text-sm px-4 py-1 rounded-full uppercase text-white;
 }
 svg{
   @apply h-4 w-4;
 }
</style>

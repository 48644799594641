<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <notifications position="left top" class="pt-5"></notifications>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body, html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @apply bg-gray-900;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  width: 100%;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

button.btb {
  @apply bg-purple-500 text-white font-bold uppercase cursor-pointer transition-colors hover:bg-pink-700 focus:outline-none appearance-none;
}
</style>

<template>
  <div class="w-full h-full bg-gray-100 grid grid-cols-12">
  <section class="col-span-8 w-full h-full py-5 flex flex-wrap items-baseline space-x-5 space-y-5 px-10 justify-start overflow-y-scroll">
    <div v-if="data" class="text-left w-full">
    <json-viewer :value="data"
                 :expand-depth=5
                 copyable
                 expanded
                 class="w-full"
                 boxed
                  />
    </div>
  </section>
    <section class="col-span-4 h-full bg--primary-darken py-5 text-left text-white px-16">
      <button class="mt-2 text-xs py-1 px-2 rounded-full focus:outline-none bg-pink-600 transition-all"
              @click="goToProject">Back to projects
      </button>

    </section>
  </div>
</template>

<script>
import blueprintApi from "@/api/blueprint";
import fire from "@/fire";
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'


export default {
  name: "ShowDBView",
  components: {JsonViewer},
  data() {
    return {
      blueprint: {},
      defaultPath: 'projects/',
      path: '/data',
      data: undefined,
      itemsRef: undefined,
    }
  },
  mounted() {
    this.loadBlueprint(this.$route.params.token)
  },
  beforeRouteLeave(to, from, next) {
    this.itemsRef = undefined
    this.data = {}
    this.blueprint = {}
    this.path = {}
    fire.database().goOffline()
    next()
  },
  methods: {
    loadBlueprint(token) {
      this.$store.dispatch('user/getUser').then(() => {
        blueprintApi.get(token, true).then(resp => {
          this.blueprint = {
            id: resp.data.id,
            name: resp.data.name,
            token: resp.data.token,
            updatedAt: resp.data.updated_at,
            project: resp.data.project
          }
          this.defaultPath += this.$store.getters['user/fireUID'] + '/' + this.blueprint.project.token + '/';
          this.firebaseConnect()
        })
      })
    },
    reconnect() {
      this.firebaseConnect()
    },
    firebaseConnect(){
      this.itemsRef = null;
      this.data = {}
      this.itemsRef = fire.database().ref(this.defaultPath + this.path)

      this.itemsRef.on("value", snapshot => {
        this.data = snapshot.val();
      })
    },
    goToProject(){
      this.$router.push({name: 'project', params: {token: this.blueprint.project.token}})
    }
  }
}
</script>

<style lang="scss">

</style>

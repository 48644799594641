<template>
  <div class="graph--settings">
    <button @click="$emit('back')">Back to project</button>
    <hr>
    <div v-if="!selectedBlock">
      <header>Blueprint settings</header>
      <default-input label="Project name" placeholder="Name" :value="name" icon="#" @input="update('name', $event)"/>
      <default-input label="Method name" placeholder="Name" :value="method" icon="/" @input="update('method', $event)"/>
      <default-select :options="httpOptions" :value="http" label="HTTP Method" @input="update('http', $event)"/>
      <div class="flex">
        <default-input label="Throttle times" placeholder="20" :value="throttleData[0]" type="number" class="w-1/2"
                       @input="update('throttleCount', $event)"/>
        <default-input label="Throttle minutes" placeholder="1" :value="throttleData[1]" type="number" class="w-1/2"
                       @input="update('throttleTime', $event)"/>
      </div>

      <default-input label="Schedule" placeholder="* * * *" :value="schedule" icon="*" @input="update('schedule', $event)"/>

      <default-select :options="locales" :value="locale" label="Locale"
                      @input="update('locale', $event)"/>

      <default-select :options="botsOptions" :value="botId" label="Telegram bot"
                      @input="update('botId', Number($event))"/>

      <button @click="$emit('activate')">{{ active ? 'Deactivate blueprint' : 'Activate blueprint' }}</button>
      <hr>
      <header>Status:</header>
      <p class="my-2 text-gray-400">Last updated at: {{ updated_at | moment('Do MMMM YYYY HH:mm:ss') }}</p>
      <p class="my-2 text-gray-400">Blocks count: {{ blocks ? blocks.length : 0 }}</p>
      <p class="my-2 text-gray-400">Links count: {{ linksCount || 0 }}</p>
      <p class="my-2 text-gray-400">Requests count: {{ requests }}</p>
      <p class="my-2 text-gray-400">Errors count: {{ errors_count || 0 }}</p>

      <button @click="$emit('save')">Save</button>
      <hr>
      <header></header>
      <label v-if="project">
        <span>Click to copy API path</span>
        <copy-line :text="'https://api.papa-carlo.ru/app/api/' + project.token + '/' + method"/>
      </label>
    </div>
    <div v-else>
      <header>"{{ selectedBlock.title }}".</header>
      <div v-html="description" class="text-sm"></div>
      <hr>
      <header>Properties</header>
      <div v-for="(slot, index) in selectedBlock.inputs" :key="slot.name + index">
        <default-input
            :disabled="checkSlot(slot.name, slot.type)"
            :type="inputType(slot.type)"
            v-if="slot.type !== 'boolean' && !textarea.includes(index)"
            :label="slot.label"
            :placeholder="slot.label"
            @input="$emit('selectedUpdate', {index, value: $event, input: true})"
            :value="slot.value"/>
        <default-textarea v-if="textarea.includes(index)"
                          :disabled="checkSlot(slot.name)"
                          :label="slot.label"
                          :placeholder="slot.label"
                          @input="$emit('selectedUpdate', {index, value: $event, input: true})"
                          :value="slot.value" />
        <small class="hover:underline cursor-pointer"
               v-if="!checkSlot(slot.name) && inputType(slot.type) === 'text'"
               @click="convertTextarea(index)"
        >
          {{ textarea.includes(index) ? 'Convert to line text' :  'Convert to textarea' }}</small>
      </div>
      <div v-for="(slot, index) in selectedBlock.outputs" :key="slot.name + index">
        <default-input
            :disabled="!slot.editable"
            :type="inputType(slot.type)"
            v-if="slot.type !== 'boolean' && !textarea.includes(index)"
            :label="slot.label"
            :placeholder="slot.label"
            @input="$emit('selectedUpdate', {index, value: $event, input: false})"
            :value="slot.value"/>
        <default-textarea v-if="textarea.includes(index)"
                          :disabled="!slot.editable"
                          :label="slot.label"
                          :placeholder="slot.label"
                          @input="$emit('selectedUpdate', {index, value: $event, input: false})"
                          :value="slot.value" />
        <small class="hover:underline cursor-pointer"
               v-if="slot.editable && inputType(slot.type) === 'text'"
               @click="convertTextarea(index)"
        >
          {{ textarea.includes(index) ? 'Convert to line text' :  'Convert to textarea' }}</small>
      </div>

    </div>

  </div>
</template>

<script>
import CopyLine from "@/components/CopyLine";
import DefaultInput from "@/components/DefaultInput";
import DefaultSelect from "@/components/DefaultSelect";
import DefaultTextarea from "@/components/DefaultTextarea";

export default {
  name: "PropertyWindow",
  components: {DefaultTextarea, DefaultSelect, DefaultInput, CopyLine},
  props: {
    leaveRoute: {
      type: Object,
    },
    http: String,
    schedule: String,
    throttle: String,
    name: String,
    method: String,
    blocks: Array,
    requests: Number,
    updated_at: String,
    errors_count: Number,
    active: {},
    bots: Array,
    botId: Number,
    project: Object,
    description: String,
    selectedBlock: Object,
    locale: String,
    linksCount: Number
  },
  computed: {
    throttleData: {
      get: function () {
        if (!this.throttle) return [0, 0]
        return this.throttle.split(',')
      }
    },
    botsOptions() {
      return this.bots.map(bot => {
        return {
          name: bot.firstName ?? bot.username,
          value: bot.id
        }
      })
    }
  },
  watch: {
    selectedBlock (){
      this.textarea = []
    }
  },
  data() {
    return {
      textarea: [],
      locales: [
        {value: 'ru', name: 'Russian'},
        {value: 'en', name: 'English'},
        {value: 'de', name: 'German'},
        {value: 'uk', name: 'Ukrainian'},
      ],
      httpOptions: [
        {value: 'GET'},
        {value: 'POST'},
        {value: 'PUT'},
        {value: 'DELETE'},
      ],
    }
  },
  methods: {
    convertTextarea(index){
      let inx = this.textarea.findIndex(f => f === index)
      if(inx > -1)
        this.textarea.splice(inx, 1)
      else
        this.textarea.push(index)
    },
    update(name, payload) {
      this.$emit('input', {name: name, value: payload})
    },
    inputType(type) {
      switch (type) {
        case 'number|string' :
          return 'text'
        case 'string|number' :
          return 'text'
        case 'string' :
          return 'text'
        case 'number' :
          return 'number'
        case 'boolean':
          return 'checkbox'
        default:
          return null
      }
    },
    checkSlot(slotName, type) {
      let goodTypes = ['number|string', 'string|number', 'string', 'number', 'boolean']
      return !!this.selectedBlock.slots.find(slot => {
        return slotName === slot.name
      }) || !goodTypes.includes(type)
    },
  }
}
</script>

<style scoped lang="scss">

button {
  @apply font-normal w-full border border-gray-700 p-2 rounded-lg;
  @apply hover:bg-gray-100 outline-none focus-within:outline-none;
  &:active {
    @apply outline-none border-blue-600 text-blue-600;
  }
}

hr {
  @apply w-full my-4 border-gray-300;
}

header {
  @apply font-bold;
}

.graph--settings {
  @apply text-xs;
  width: 350px;
  @apply bg-gray-50 text-gray-700 shadow-md;
  @apply h-full py-5 text-left px-5  overflow-y-scroll overflow-x-hidden;
}
</style>

<template>
<div class="blocks-view">
  <header>Blocks</header>
  <dropdown-list :blocks="blocks" :type="type" v-for="(blocks, type) in blocksByType" :key="type" @select="$emit('select', $event)"/>
  <hr class="my-4">
  <keyboard-line :keys="['Shift', 'D']" info="Clone block"/>
</div>
</template>

<script>
import DropdownList from "@/components/DropdownList";
import KeyboardLine from "@/components/KeyboardLine";
export default {
  name: "BlocksView",
  components: {KeyboardLine, DropdownList},
  props: ['blocksByType']
}
</script>

<style scoped>
.blocks-view{
  width: 250px;
  @apply text-xs;
  @apply bg-gray-50 text-gray-700 shadow-md;
  @apply h-full py-5 text-left px-5  overflow-y-scroll overflow-x-hidden;
}

header {
  @apply font-bold;
}

</style>

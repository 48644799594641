import store from "@/store";
import fire from "@/fire";

const FirebaseAutosave = {
		data() {
				return {
						autoSaveEnabled: false
				}
		},
		watch: {
				//TODO: Сохранение изменений в firebase
				blocks: function (newData) {
						if (!this.autoSaveEnabled) return
						if (this.$store.getters['user/fireUID'])
								fire.database().ref(this.ref).set(newData)
						else
								store.dispatch('user/getUser').then(() => {
										fire.database().ref(this.ref).set(newData)
								})
				}
		},
		computed: {
				ref() {
						if (!this.blueprint) return;
						return 'projects/' + this.$store.getters['user/fireUID'] + '/'
									+ this.blueprint.project.token + '/blueprints/'
									+ this.blueprint.token + '/autosave'
				}
		},
		methods: {
				firebaseLink() {
						if (!this.autoSaveEnabled) return
						store.dispatch('user/getUser').then(() => {
								fire.database().ref(this.ref).on('value', snapshot => {
										const data = snapshot.val()
										console.log(data, this.blocks, 'Firebase data')
								})
						})
				},
				updateFirebaseAutosave(block, index) {
						if (!this.autoSaveEnabled) return
						fire.database().ref(this.ref + '/' + index).update(block)
				},
		}
}

export default FirebaseAutosave

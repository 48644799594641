<template>
<div class="blocks--dropdown" :class="{'blocks--dropdown-open' : open }" >
  <header @click="open = !open">
    <div class="ic" v-html="arrow"></div>
    <div v-html="icon" class="w-4 h-4"></div>
    <span>{{ type }}</span>
  </header>
  <ul v-if="open">
    <li v-for="block in blocks" :key="block.name" @click="$emit('select', block)">{{ block.title || block.name }}</li>
  </ul>
</div>
</template>

<script>
import settings from "@/blocks";
export default {
  name: "DropdownList",
  props: ['blocks', 'type'],
  data() {
    return {
      open: false,
      arrow: '<svg xmlns="http://www.w3.org/2000/svg" color="currentColor" viewBox="0 0 552.611 552.611"><defs/><path d="M486.413 221.412L122.347 12.916c-52.938-30.318-95.852-5.44-95.852 55.563v415.652c0 61.004 42.914 85.882 95.852 55.563l364.066-208.49c52.938-30.317 52.938-79.473 0-109.792z"/></svg>',
    }
  },
  computed: {
    icon(){
      if(!settings[this.type]) return ''
      return settings[this.type].icon
    }
  }
}
</script>

<style scoped lang="scss">
.blocks--dropdown {
  header {
    @apply flex space-x-2 cursor-pointer hover:border-blue-600 py-2 items-center border-b border-transparent font-bold text-gray-400;
  }
  .ic {
    @apply w-2 h-2 transition-transform opacity-40;
  }

  &-open{
    header {
      @apply text-gray-800;
    }
    .ic {
      @apply transform rotate-90 opacity-100
    }
  }

  li {
    @apply py-2 hover:border-blue-600 py-2 items-center border-b border-transparent cursor-pointer;
  }
}
</style>

import api from '@/api/api'

export default {
  login (email, password) {
    return new Promise((resolve, reject) => {
      api.call('post', 'auth/login', { email: email, password: password })
         .then(resp => {
           api.setToken(resp.data.token)
           resolve(resp)
         }).catch(error => {
        reject(error)
      })
    })
  },

  get () {
    return api.call('get', 'auth/user')
  },

  refreshToken(){
    return api.call('get', 'auth/refresh')
  },

  connectionToken(){
    return api.call('get', 'auth/wsToken')
  },

  getAll () {
    return api.call('get', 'getAllUsers')
  },

  logout () {
    return new Promise((resolve, reject) => {
      api.call('post', 'auth/logout')
         .then(resp => {
           api.removeToken()
           resolve(resp)
         }).catch(error => {
        reject(error)
      })
    })
  },

  create () {},

  find (text) {
    return new Promise((resolve, reject) => {
      api.call('get', 'user/find', {
        params: {
          text: text
        }
      }).then(resp => { resolve(resp) })
         .catch(err => { reject(err) })
    })
  }
}

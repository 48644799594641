<template>
  <div class="w-full h-full bg-gray-100 grid grid-cols-12">
  <section class="col-span-8 w-full h-full py-5 flex flex-wrap items-baseline content-start space-y-5 px-10 justify-start overflow-y-scroll">
    <project-card
        class="mr-5"
        v-for="project in projects"
        :key="project.id"
        @clickOpen="$router.push({ name: 'project', params: { token: project.token }})"
        :name="project.name"
        :token="project.token"
        :active="project.active"
        :blueprints-count="project.blueprints_count"
    />
  </section>
    <section class="col-span-4 h-full bg--primary-darken py-5 text-left text-white px-16">
      <h4 class="font-bold text-xl">Create project</h4>
      <input type="text"
             v-model="newName"
             class="mt-2 text-lg py-2 px-4 rounded-full focus:outline-none text-gray-800 block"
             placeholder="Название" />
      <button class="mt-2 text-lg py-2 px-4 rounded-full focus:outline-none bg-pink-600 transition-all"
              :class="{'opacity-0 w-0': newName.length < 4, 'opacity-100': newName.length >= 4}"
              @click="createProject"
              :disabled="newName.length < 4">Create</button>
<!--      <div class="cursor-pointer bg-red-300" @click="test">TEST!!!!</div>-->
<!--      <div class="mt-4">Good: {{ ok }} <br> Bad: {{ bad }} <br> Повтор: {{ total }} <br> Учтен: {{okioki}}</div>-->
<!--      <textarea name="" id="" cols="30" rows="10" class="mt-2 rounded-xl text-gray-800 px-2 pt-4" v-model="text"></textarea>-->
    </section>
  </div>
</template>

<script>
import ProjectCard from "./Projects/ProjectCard";
import project from "@/api/project";
import axios from "axios";
export default {
  name: "Projects",
  components: {ProjectCard},
  data() {
    return {
      projects: [],
      newName: '',
      ok:0,bad:0,total:0, text:'', okioki:0
    }
  },
  mounted() {
    project.getAll().then(resp => {
      this.projects = resp.data
    })
  },
  methods: {
    test(){
      let p = 'https://api.papa-carlo.ru/app/api/botcce3035a-b953-4796-8a02-809bee4ae10d/run';
      let headers = {
        'User-Agent': 'PostmanRuntime/7.26' + Math.floor(Math.random() * 200),
      }

      for(let i = 0; i<10; i++) {
        headers = {
          'User-Agent': 'PostmanRuntime/7.26.' + Math.floor(Math.random() * 200),
        }
        axios.post(p, {"vote_for": "Команда " + Math.floor(Math.random() * 9)}, {headers}).then(resp => {
          this.text = resp.data.data[0].message + '\r\n' + this.text
          this.ok++
          console.log(resp.data[0].new)
          if(!resp.data[0].new)
            this.total++
          else
            this.okioki++
        }).catch(() => this.bad++)
      }

    },
    createProject() {
      project.create(this.newName)
          .then((resp) => {
            this.projects.push({
              id: resp.data.id,
              name: resp.data.name,
              updatedAt: resp.data.updated_at,
              token: resp.data.token
            })

            this.newName = ''
          })
    },
    hashCode(str) { // java String#hashCode
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    intToRGB(i) {
      let c = (i & 0x00FFFFFF)
          .toString(16)
          .toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    }
  }
}
</script>

<style lang="scss">

</style>

<template>
<div class="copy--line" :class="{'border-green-500': copied, 'border-transparent': !copied }" @click="doCopy">
  <input :value="text" disabled>
</div>
</template>

<script>
export default {
  name: "CopyLine",
  props: {
    text: {
      default: 'Lorem ipsum lorem ipsum lorem ipsum'
    }
  },
  data() {
    return {
      copied: false
    }
  },
  methods: {
    doCopy: function () {
      let t = this
      this.$copyText(this.text).then(function () {
        t.copied = true
        t.$notify({
          title: 'Скопировано',
          type: 'success'
        })
        setTimeout(() => t.copied = false, 1500)
      }, function () {
        //alert('Can not copy')
      })
    }
  }
}
</script>

<style scoped lang="scss">
 .copy--line{
   @apply cursor-pointer transition-colors delay-75 border;

   input{
     @apply w-full overflow-hidden whitespace-nowrap p-2 cursor-pointer bg-transparent;
     @apply hover:bg-gray-100;
   }


 }
</style>

import user from '@/api/user'

// initial state
const state = () => ({
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
})

// getters
const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  userFullName: state => state.user.full_name ?? 'Имя Фамилия',
  userShortName: state => state.user.short_name ?? 'Имя Ф.',
  userEmail: state => state.user.email ?? 'email@papa-carlo.ru',
  fireUID: state => state.user.fireUID ?? null,
  userID: state => state.user.id ?? null,
  userAvatar: state => state.user.avatar ?? null,
  user: state => state.user ?? {}
}

// actions
const actions = {
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('authRequest')
      user.login(userData.email, userData.password)
        .then(resp => {
          commit('authSuccess', { token: resp.data.token, user: resp.data.user })
          resolve(resp)
        })
        .catch(err => {
          commit('authError')
          this.dispatch('logout')
          reject(err)
        })
    })
  },
  refresh({ commit }){
    return new Promise((resolve, reject) => {
      commit('authRequest')
      user.refreshToken()
         .then(resp => {
           commit('refreshSuccess', { token: resp.data.token, user: resp.data.user })
           resolve(resp)
         })
         .catch(err => {
           commit('authError')
           this.dispatch('logout')
           reject(err)
         })
    })
  },
  getUser ({ commit }) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('token')) resolve({})
      commit('authRequest')

      user.get()
        .then(resp => {
          commit('getSuccess', { user: resp.data.user })
          resolve(resp)
        })
        .catch(err => {
          commit('authError')
          this.dispatch('logout')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('authRequest')
      user.logout()
        .then(() => {
          commit('logout')
          resolve()
        })
        .catch(err => {
          commit('authError', err)
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  authRequest (state) {
    state.status = 'loading'
  },
  authSuccess (state, payload) {
    state.status = 'success'
    state.token = payload.token
    state.user = payload.user
  },
  getSuccess (state, payload) {
    state.status = 'success'
    state.user = payload.user
  },
  refreshSuccess (state, payload){
    state.token = payload.token
    state.user = payload.user
  },
  authError (state) {
    state.status = 'error'
  },
  logout (state) {
    state.status = ''
    state.token = ''
    state.user = {}
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

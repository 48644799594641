import Projects from "../components/Projects";

import Login from "../components/Login";
import Database from "../components/Database";

import VueRouter from 'vue-router'
import Blueprints from "../components/Blueprints";

import store from '../store'
import Errors from "@/components/Errors";
import GraphView from "@/components/GraphView/GraphView";

const routes = [
    {path: '', name: 'home', component: Projects, meta: {auth: true}},
    {path: '/project/:token', name: 'project', component: Blueprints, meta: {auth: true}},
    {path: '/blueprint/:token', name: 'editBlueprint', component: GraphView, meta: {auth: true}},
    {path: '/errors/:token', name: 'errors', component: Errors, meta: {auth: true}},
    {name: 'database', path: '/db/:token', component: Database, meta: {auth: true}},
    {name: 'login', path: '/login', component: Login, meta: {auth: false}},
    {name: 'test', path: '/test', component: GraphView, meta: {auth: false}}
]

let router = new VueRouter({
    mode: 'history',
    routes // сокращённая запись для `routes: routes`
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (store.getters["user/isLoggedIn"]) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router

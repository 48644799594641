<template>
  <div class="w-full h-full bg-gray-100 grid grid-cols-12">
    <section class="col-span-8 w-full h-full py-5 px-10 overflow-y-scroll space-y-5">

      <div class="" v-for="(e, hash) in errors" :key="hash">
<!--        <div class="text-left px-4 py-5 flex justify-between rounded-xl mb-2 text-xs bg-gray-50 text-gray-700 shadow-md"
             :class="{'font-bold bg-gray-600 text-white' : selectedGroup === hash }"
        >
          <p>Block {{ erGroup[0].blockId }}. {{ erGroup[0].message }}</p>
          <p>Errors count: {{ erGroup.length }} </p>
          <p>Last error: {{ erGroup[0].created_at | moment('HH:mm:ss, Do MMM') }}</p>
          <p @click="selectedGroup === hash ? selectedGroup = undefined : selectedGroup = hash">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
            </svg>
          </p>
        </div>
        <div class="space-y-2" v-if="selectedGroup === hash">-->
          <error-card
              :hash="e[0].hash"
              :message="e[0].message"
              :block-id="e[0].blockId"
              :count="e.length"
              :trace="e[0].trace"
              :file="e[0].file"
              :line="e[0].line"
              :headers="e[0].headers"
              :data="e[0].data"
              :browser="e[0].browser"
              :updated-at="e[0].updated_at"
              @delete="removeError(hash)"
          />
<!--        </div>-->
      </div>


    </section>
    <section class="col-span-4 h-full bg--primary-darken py-5 text-left text-white px-16">
      <button class="mt-2 text-xs py-1 px-2 rounded-full focus:outline-none bg-pink-600 transition-all"
              @click="$router.back()">Back
      </button>
    </section>
  </div>
</template>

<script>
import errorsApi from "@/api/errors";
import ErrorCard from "@/components/Errors/ErrorCard";

export default {
  name: "Errors",
  components: {ErrorCard},
  data() {
    return {
      errors: [],
      blueprintToken: undefined,
      selectedGroup: undefined
    }
  },
  mounted() {
    this.blueprintToken = this.$route.params.token
    this.getErrors()
  },
  methods: {
    getErrors() {
      errorsApi.get(this.blueprintToken).then(resp => this.errors = resp.data.errors)
    },
    removeError(id) {
      this.$confirm(
          {
            message: `Are you sure?`,
            button: {no: 'No', yes: 'Yes'},
            callback: confirm => {
              if (confirm) {
                errorsApi.remove(id).then(() => this.getErrors())
              }
            }
          }
      )
    }
  }
}
</script>

<style scoped>
svg {
  @apply h-4 w-4 hover:opacity-30 transition-opacity cursor-pointer;
}

span {
  @apply font-bold text-gray-300 block;
}
</style>

import axios from 'axios'
import Vue from 'vue'
//import store from '../store'

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://api.papa-carlo.ru/app/api' : 'https://api.papa-carlo.ru/app/api',
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
})

const debug = process.env.NODE_ENV !== 'production'

export default {
  call (requestType, url, data = null, showError = true) {
    return new Promise((resolve, reject) => {
      http[requestType](url, data).then(response => {
           if (debug) console.log('API Call', url, data, response)
           resolve(response)
         })
         .catch(({ response }) => {
           /*if (response.status === 401)
													store.dispatch('user/refresh')*/
           if (response.status === 503)
             this.showWaitingError()
           else if (response.status === 429)
             this.showTooManyRequestWarning()
           else if (response.status === 422 || response.status === 404 || response.status === 400) {
             if (showError) this.showInfoError(response.data.errors)
           } else
             this.showSystemError(response)

           reject(response)
         })
    })
  },

  setToken(token){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    localStorage.setItem('token', token)
  },

  removeToken(){
    delete axios.defaults.headers.common['Authorization']
    localStorage.removeItem('token')
  },

  getColors(){
    return this.call('get', 'colors')
  },

  showTooManyRequestWarning () {
    Vue.notify({
      duration: 5000,
      title: 'Слишком много запросов 😕',
      type: 'error',
      text: 'Ну чуть меньше плиз. Зачем столько кнопочек нажимать? '
    })
  },

  showSystemError (response) {
    console.log(response);
    Vue.notify({
      duration: 10000,
      title: 'Произошла ошибка 😱',
      type: 'error',
      text: 'Мы сами не знаем что произошло, но наши миньоны уже в курсе и ищут её.'
    })
  },

  showWaitingError () {
    //console.log(response);
    Vue.notify({
      duration: 10000,
      title: 'Одну минуточку. Мы обновляемся 🥴',
      type: 'warn',
      text: 'Вот прямо сейчас сервер обновляется. Это занимает не больше 4х минут, попробуйте еще раз через 2-4 минуты ;)'
    })
  },

  showInfoError (errors) {
    let arr = Object.values(errors)
    Vue.notify({
      duration: 4000,
      title: 'Ой, что-то не так',
      type: 'warn',
      text: arr.join('<br>')
    })
  }
}

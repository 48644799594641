<template>
  <div class="rounded-3xl bg-gray-50 w-full py-5 px-6 text-xs shadow-md grid grid-cols-12 items-start text-left">
    <div class="col-span-1"><span>Block ID</span> {{ blockId }}</div>
    <div class="col-span-6"><span>Message</span> {{ message }}</div>
    <div class="col-span-1"><span>Attempts</span> {{ count }}</div>
    <div class="col-span-2"><span>Last attempt</span>{{ updatedAt | moment('HH:mm:ss, Do MMM') }}</div>
    <div class="col-span-1 self-center" @click="showDetails = !showDetails">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="!showDetails">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
      </svg>

    </div>
    <div class="col-span-1 self-center" @click="$emit('delete')">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
      </svg>
    </div>
    <div class="col-span-12 w-full mt-5 text-left" v-if="showDetails">
      <div class="mb-5">
        <b>Message: {{ message }}</b> <br>
        File: {{ file }} <br>
        Line: {{ line }}
      </div>

      <h4 class="mt-5 font-bold text-lg">Trace:</h4>
      <json-viewer
          :value="trace"
          :expand-depth=3
          copyable
          class="w-full"
          boxed
      />

      <h4 class="mt-5 font-bold text-lg">Data:</h4>
      <div v-for="(dataValue, dataName) in data" :key="dataName" class="flex mt-2">
        <span class="font-bold text-gray-500 w-2/12">{{ dataName }}</span>
        <p class="px-2 py-1 bg-gray-100 w-10/12 text-gray-800 font-normal overflow-hidden">{{ dataValue }}</p>
      </div>

      <h4 class="mt-5 font-bold text-lg">Headers:</h4>
      <div v-for="(headerValue, headerName) in headers" :key="headerName" class="flex mt-2">
        <span class="font-bold text-gray-500 w-2/12">{{ headerName }}</span>
        <p class="px-2 py-1 bg-gray-100 w-10/12 text-gray-800 font-normal overflow-hidden">{{ headerValue }}</p>
      </div>

      <h4 class="mt-5 font-bold text-lg">Browser:</h4>
      <div v-for="(bValue, bName) in browser" :key="bName" class="flex mt-2">
        <span class="font-bold text-gray-500 w-2/12">{{ bName }}</span>
        <p class="px-2 py-1 bg-gray-100 w-10/12 text-gray-800 font-normal overflow-hidden">{{ bValue }}</p>
      </div>

<!--      <textarea class="rounded w-full h-60 focus:outline-none px-3 py-2" :value="trace"></textarea>-->
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: "ErrorCard",
  components: {JsonViewer},
  props: {
    blockId:{}, hash:{}, file:{}, line:{}, updatedAt:{}, count:{}, trace: {}, message:{}, browser: {}, headers: {}, data: {}
  },
  data(){
    return{
      showDetails: false
    }
  },
}
</script>

<style scoped>
svg{
  @apply h-4 w-4 hover:opacity-30 transition-opacity cursor-pointer;
}

span {
  @apply font-bold text-gray-300 block;
}
</style>

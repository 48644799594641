<template>
  <div class="bl" :class="{'sl': selected}" :style="style">
    <header>
      <div v-html="icon" class="ic"></div>
      <span>{{ id }}. {{ $attrs.title }}</span>
    </header>
    <a class="dlt" @click="deleteBlock"></a>
    <div class="space-y-2">
      <div class="inp" v-for="(slot, index) in inputs" :key="index + Math.random()">
        <div class="lnk iSlt" :class="{active: slot.active}"
             @mouseup="slotMouseUp($event, index)"
             @mousedown="slotBreak($event, index)"></div>
        <label>
          <input :placeholder="slot.label"
                 :disabled="checkSlot(slot.name, slot.type)"
                 :type="inputType(slot.type)"
                 :style="{maxWidth: (options.width - 40) + 'px'}"
                 :value="slot.value"
                 :checked="slot.value"
                 @input="update(index, true, $event)"
          />
          <span v-if="slot.type === 'boolean'">{{ slot.label }}</span>
        </label>
      </div>

      <div class="out" v-for="(slot, index) in outputs" :key="index + Math.random()">
        <label>
          <input :placeholder="slot.label"
                 :disabled="!slot.editable"
                 :type="outputType(slot.type)"
                 :style="{maxWidth: (options.width - 40) + 'px'}"
                 :value="slot.value"
                 :checked="slot.value"
                 @input="update(index, false, $event)"
          />
        </label>
        <div class="lnk iSlt" :class="{active: slot.active}"
             @mousedown="slotMouseDown($event, index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import blockSettings from "@/blocks";

export default {
  name: "Block",
  props: {
    id: Number,
    x: {
      type: Number,
      default: 0,
      validator: function (val) {
        return typeof val === 'number'
      }
    },
    y: {
      type: Number,
      default: 0,
      validator: function (val) {
        return typeof val === 'number'
      }
    },
    selected: Boolean,
    slots: Array,
    inputs: Array,
    outputs: Array,
    options: Object
  },
  data() {
    return {
      width: 200,
      mouseX: 0,
      mouseY: 0,
      lastMouseX: 0,
      lastMouseY: 0,
      linking: false,
      dragging: false,
      inputsValues: []
    }
  },
  mounted() {
    //this.addListeners()
    document.documentElement.addEventListener('mousedown', this.handleDown, true)
  },
  beforeDestroy() {
    this.removeListeners()
    document.documentElement.removeEventListener('mousedown', this.handleDown, true)
  },
  methods: {
    inputType(type) {
      switch (type) {
        case 'number|string' || 'string|number' || 'string' :
          return 'text'
        case 'number' :
          return 'number'
        case 'boolean':
          return 'checkbox'
        default:
          return false
      }
    },
    outputType(type) {
      switch (type) {
        case 'number|string' || 'string|number' || 'string' :
          return 'text'
        case 'number' :
          return 'number'
        default:
          return false
      }
    },
    checkSlot(slotName, type) {
      let goodTypes = ['number|string', 'string|number', 'string', 'number', 'boolean']
      return !!this.slots.find(slot => {
        return slotName === slot.name
      }) || !goodTypes.includes(type)
    },
    //Move
    addListeners() {
      document.documentElement.addEventListener('mousemove', this.handleMove, true)
      document.documentElement.addEventListener('mouseup', this.handleUp, true)
    },
    removeListeners() {
      document.documentElement.removeEventListener('mousemove', this.handleMove, true)
      document.documentElement.removeEventListener('mouseup', this.handleUp, true)
    },
    handleMove(e) {
      this.mouseX = e.pageX || e.clientX + document.documentElement.scrollLeft
      this.mouseY = e.pageY || e.clientY + document.documentElement.scrollTop
      if (this.dragging && !this.linking) {
        let diffX = this.mouseX - this.lastMouseX
        let diffY = this.mouseY - this.lastMouseY

        this.lastMouseX = this.mouseX
        this.lastMouseY = this.mouseY

        this.moveWithDiff(diffX, diffY)

        this.hasDragged = true
      }
    },
    handleDown(e) {
      this.mouseX = e.pageX || e.clientX + document.documentElement.scrollLeft
      this.mouseY = e.pageY || e.clientY + document.documentElement.scrollTop

      this.lastMouseX = this.mouseX
      this.lastMouseY = this.mouseY

      const target = e.target || e.srcElement
      if (target.tagName !== 'INPUT') {
        if (this.$el.contains(target) && e.which === 1) {
          this.dragging = true
          this.$emit('select')

          if (e.preventDefault) e.preventDefault()
        }
      }
    },
    handleUp() {
      if (this.dragging) {
        this.dragging = false

        if (this.hasDragged) {
          this.hasDragged = false
        }
      }

      if (this.linking) {
        this.linking = false
      }

      this.save()
    },
    moveWithDiff(diffX, diffY) {
      let left = this.x + diffX / this.options.scale
      let top = this.y + diffY / this.options.scale
      this.$emit('update:x', left)
      this.$emit('update:y', top)
    },
    // Slots
    slotMouseDown(e, index) {
      this.linking = true

      this.$emit('linkingStart', index)
      if (e.preventDefault) e.preventDefault()
    },
    slotMouseUp(e, index) {
      this.$emit('linkingStop', index)
      if (e.preventDefault) e.preventDefault()
    },
    slotBreak(e, index) {
      this.linking = true

      this.$emit('linkingBreak', index)
      if (e.preventDefault) e.preventDefault()
    },
    //Save Delete
    save() {
      this.$emit('update')
    },
    deleteBlock() {
      this.$emit('delete')
    },
    update(index, input, e){
      let val = e.target.type === 'checkbox' ? e.target.checked : e.target.value
      this.$emit('upInput', {input, value: val, slotIndex: index, blockID: this.id})
    }
  },
  computed: {
    style() {
      return {
        top: this.options.center.y + this.y * this.options.scale + 'px',
        left: this.options.center.x + this.x * this.options.scale + 'px',
        width: this.width + 'px',
        transform: 'scale(' + (this.options.scale + '') + ')',
        transformOrigin: 'top left'
      }
    },
    icon() {
      return blockSettings[this.$attrs.family] ? blockSettings[this.$attrs.family].icon : ''
    }
  },
  watch: {
    selected: function (sel) {
      if (sel) this.addListeners()
      else this.removeListeners()
    }
  }
}
</script>

<style scoped lang="scss">
$ioHeight: 24px;
$ioFontSize: 14px;
$ioPadding: 2px 0;
$blockBorder: 0px;

.bl {
  @apply rounded-lg bg-white shadow-sm absolute p-4 cursor-move;

  header {
    @apply select-none flex items-center space-x-2 mb-4 w-11/12 overflow-ellipsis whitespace-nowrap;
    .ic {
      @apply w-4 h-4;
    }
    @apply absolute -top-6 text-xs font-bold text-gray-400 left-0 ;
  }

  //Delete button
  > .dlt {
    @apply float-right text-sm cursor-pointer text-red-300
    transition-colors hover:text-red-600 hover:bg-red-300
    bg-center bg-no-repeat
    absolute -right-1.5 -top-1.5 bg-red-600 w-4 h-4 rounded-full text-center;
    background-image: url("../assets/images/x-white.svg");
    background-size: 6px;
    line-height: 1.1rem;
    font-size: 10px;
  }

  //Selected
  &.sl {
    @apply bg-gray-100 z-10 shadow-2xl;
    header{
      @apply text-gray-700;
    }
  }

  //Link block
  .lnk {
    @apply box-border mt-1 w-3 h-3 bg-gray-400 transition-colors inline-block relative;
    cursor: crosshair;

    sup {
      @apply absolute text-gray-600;
      left: 4px;
      top: 5px;
    }

    &.active {
      @apply bg-purple-500;
      sup {
        @apply absolute text-purple-800;
      }
    }

    &:hover {
      @apply bg-yellow-400;
      sup {
        @apply absolute text-yellow-600;
      }
    }

    &:hover.active {
      @apply bg-red-400;
    }
  }

  .inp, .out {
    @apply flex space-x-3 items-center;
    > label {
      @apply flex items-center space-x-2;
      //Checkbox input label
      & > span {
        @apply ml-2
      }
    }
    input {
      @apply border border-gray-200 px-1 outline-none focus:border-gray-500 cursor-auto;
      &:disabled{
        @apply border-transparent text-gray-400 bg-transparent;
      }
    }

  }

  //Inputs
  .inp {
    @apply -mx-5;
  }

  //Outputs
  .out {
    @apply -mr-5 float-right;
    input {
      @apply text-right
    }
    .circle {
      @apply float-right
    }
  }
}

</style>

<template>
<div class="key--line">
  <div v-for="(key, i) in keys" :key="key+i" class="key--inside">
    <div class="key">{{ key }}</div>
    <div class="plus" v-if="i < keys.length - 1">+</div>
  </div>
  <div class="info">{{ info }}</div>
</div>
</template>

<script>
export default {
  name: "KeyboardLine",
  props: {
    keys: {
      type: Array,
      required: true
    },
    info: String
  }
}
</script>

<style scoped lang="scss">
.key--line {
  @apply flex items-center space-x-1 text-gray-400;
  .key--inside {
    @apply flex items-center space-x-1;
  }
  .plus {
    @apply font-bold;
  }
  .key{
    @apply py-1 border border-gray-400 px-2 rounded;
  }
  .info {
    @apply pl-2;
  }
}
</style>

import api from '@/api/api'

export default {
  getAll () {
    return api.call('get', 'bot')
  },
  create (token) {
    return api.call('put', 'bot', {token})
  },
  setWebhook(botId, blueprintToken){
    return api.call('post', `bot/${botId}/setWebhook`, {blueprintToken})
  }
}

<template>
  <div class="h-screen w-screen relative flex justify-center items-center">
    <div
        class="bg"
        :class="{'opacity-0' : !load, 'opacity-100': load}"
        :style="{backgroundImage: 'url(' + image + ')'}"
    ></div>
    <div class="login-form">
      <header>
        <img src="../assets/images/icon.png" class="h-32">
        <p>the.api</p>
      </header>
      <form @submit.prevent="login" class="mt-10">
        <input type="email" autocomplete="email" class="" placeholder="email" v-model="form.email">
        <input type="password" autocomplete="current-password" class="" placeholder="password" v-model="form.password">
        <button @click="login" :disabled="wait" :class="{'opacity-80 cursor-normal' : wait }">
          {{ wait ? "Wait..." : "Login" }}
        </button>
      </form>

      <img :src="require('@/assets/images/papa-carlo.svg')" alt="Papa Carlo" class="h-3 mt-12"/>

    </div>
  </div>
</template>

<script>

import fire from "@/fire";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      bgImages: [
        1, 2, 3, 4, 5, 6, 7
      ],
      load: false,
      image: null,
      wait: false,
    }
  },
  mounted() {
    this.image = require('@/assets/images/BG/' + this.bgImages[Math.floor(Math.random() * this.bgImages.length)] + '.jpg')
    setTimeout(() => this.load = true, 500);
  },
  methods: {
    errors(res) {
      this.form.errors = Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]));
    },
    login() {
      this.wait = true;
      /*this.$auth.login({ data: this.form, staySignedIn: true })
      console.log(this.$auth)*/
      this.$store.dispatch('user/login', {
        email: this.form.email,
        password: this.form.password
      }).then(() => this.$router.push({name: 'home'})).catch(err => {
        this.message = err.data.errors[0]
      })

      // eslint-disable-next-line no-unused-vars
      fire.auth().signInWithEmailAndPassword(this.form.email, this.form.password).catch(err => {
        //this.$store.dispatch('user/logout')
      })
    }
  }
}
</script>

<style scoped lang="scss">

.login-form{
  @apply absolute
  xl:right-32 w-11/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12
  py-10 px-20
  bg-gray-800 bg-opacity-90
  shadow-2xl text-gray-200;
  border-radius: 50px;

  p {
    @apply text-3xl;
  }

  img {
    @apply mb-2 mx-auto
  }
}

input {
  @apply w-full rounded-full px-5 py-3 my-2 focus-within:outline-none focus:ring ring-pink-600 shadow-xl text-gray-800 font-bold;
}

button {
  @apply bg-gradient-to-t from-pink-600 to-pink-500
  my-2
  shadow-xl rounded-full px-5 py-3 w-full
  text-white font-bold uppercase tracking-widest
  focus-within:outline-none focus:ring ring-pink-600 ring-1
  cursor-pointer transition-colors hover:bg-pink-700;
}

.bg {
  @apply bg-cover bg-left w-full h-full transition-all duration-1000 relative;
}

</style>

import api from '@/api/api'

export default {
  getAll () {
    return api.call('get', 'blueprint')
  },
  get (token, withProject = false) {
    return api.call('get', 'blueprint/' + token + (withProject ? '?withProject=true' : '' ))
  },
  create (name, method, http, projectToken) {
    return api.call('post', 'blueprint/create', { name, method, http, projectToken })
  },
  save (token, data) {
    return api.call('post', 'blueprint/save/' + token, data)
  },
  clone (token) {
    return api.call('post', 'blueprint/clone/' + token)
  },
  delete (token) {
    return api.call('delete', 'blueprint/' + token)
  },
  getBlocks(){
    return api.call('get', 'blocks')
  },
  runBlueprint(token, data = null){
    return api.call('post', 'bot' + token + '/run', data)
  }
}

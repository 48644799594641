import api from '@/api/api'

export default {
  getAll () {
    return api.call('get', 'project')
  },
  get (token) {
    return api.call('get', 'project/' + token)
  },
  create (name) {
    return api.call('post', 'project/create', { name: name })
  },
  save(token, data){
    return api.call('post', 'project/save/' + token, data)
  }
}

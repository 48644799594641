<template>
  <div class="graph--settings">
    <button @click="$emit('back')">Back to projects</button>
    <hr>
    <div>
      <header>Create blueprint</header>
      <default-input label="Project name" placeholder="Name" :value="null" icon="#" @input="update('name', $event)"/>
      <default-input label="Method name" placeholder="Name" :value="null" icon="/" @input="update('method', $event)"/>
      <default-select :options="httpOptions" :value="null" label="HTTP Method" @input="update('http', $event)"/>
      <button @click="$emit('create')">Create</button>

      <hr>
      <header>Project status:</header>
      <button @click="$emit('activate')" class="mt-2">{{ activeProject ? 'Deactivate project' : 'Activate project' }}</button>

      <hr>
      <header>Create telegram bots:</header>
      <default-input label="Bot API Token" v-model="newToken" placeholder="Enter telegram api token"/>
      <button @click="createBot" :disabled="newToken.length < 10">Create</button>

      <hr>
      <header>Telegram bot setting</header>
      <default-select :options="botsOptions" v-model="selectedBot" label="Select bot"/>
      <div v-if="selectedBot">
        <default-select :options="blueprintOptions" v-model="selectedBlueprint" label="Select blueprint for set webhook"/>
        <button @click="setBotWebhook" :disabled="!selectedBot || !blueprintTokenForWebhook">Set webhook</button>
      </div>
    </div>

  </div>
</template>

<script>
import DefaultInput from "@/components/DefaultInput";
import DefaultSelect from "@/components/DefaultSelect";
import botApi from "@/api/bot"

export default {
  name: "ProjectPropertyWindow",
  components: {DefaultSelect, DefaultInput},
  props: {
    leaveRoute: {
      type: Object,
    },
    activeProject: {},
    blueprints: Array
  },
  computed: {
    botsOptions() {
      return this.bots.map(b => {
        return {
          value: b.id,
          name: b.firstName ?? b.username
        }
      })
    },
    blueprintOptions() {
      return this.blueprints.map(b => {
        return {
          value: b.token,
          name: b.name + ' /' + b.method
        }
      })
    },
    selectedBlueprint: {
      get: function () {
        if(!this.selectedBot) return null
        let bl = this.blueprints.find(b => b.botId === this.selectedBot)
        if(bl) return bl.token
        else return null
      },
      set: function (value){
        this.blueprintTokenForWebhook = value
      }
    }
  },
  data() {
    return {
      httpOptions: [
        {value: 'GET'},
        {value: 'POST'},
        {value: 'PUT'},
        {value: 'DELETE'},
      ],
      bots: [],
      newToken: '',
      selectedBot: null,
      blueprintTokenForWebhook: null,
    }
  },
  mounted() {
    this.loadBots()
  },
  methods: {
    update(name, payload) {
      this.$emit('input', {name: name, value: payload})
    },
    loadBots(){
      botApi.getAll().then(resp => {
        this.bots = resp.data.bots
      })
    },
    createBot(){
      botApi.create(this.newToken).then(() => {
        this.$notify({
          title: 'Bot created',
          type: 'success'
        })
        this.loadBots()
        this.newToken = ''
      })
    },
    setBotWebhook(){
      botApi.setWebhook(this.selectedBot, this.blueprintTokenForWebhook).then(() => {
        this.$notify({
          title: 'Bot webhook set',
          type: 'success'
        })
      })
      this.loadBots()
      this.selectedBot = null
      this.blueprintTokenForWebhook = null
    }
  },
}
</script>

<style scoped lang="scss">

button {
  @apply font-normal w-full border border-gray-700 p-2 rounded-lg;
  @apply hover:bg-gray-100 outline-none focus-within:outline-none;
  &:active {
    @apply outline-none border-blue-600 text-blue-600;
  }
  &:disabled{
    @apply opacity-60 cursor-default hover:bg-transparent;
  }
}

hr {
  @apply w-full my-4 border-gray-300;
}

header {
  @apply font-bold;
}

.graph--settings {
  @apply text-xs;
  width: 350px;
  @apply bg-gray-50 text-gray-700 shadow-md;
  @apply h-full py-5 text-left px-5  overflow-y-scroll overflow-x-hidden;
}
</style>

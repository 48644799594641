import domHelper from "@/assets/vue-blocks-master/src/helpers/dom";

const ContextMenuMixin = {
		data() {
				return {
						hoverDescription: 'Has no description',
						contextMenu: {
								isShow: false,
								mouseX: 0,
								mouseY: 0,
								top: 0,
								left: 0,
								data: null
						},
				}
		},
		methods: {
				addBlockContextMenu(name) {
						let offset = domHelper.getOffsetRect(this.$refs.graph)
						let x = this.contextMenu.mouseX - offset.left
						let y = this.contextMenu.mouseY - offset.top

						this.addNewBlock(this.blocksData.find(b => b.name === name), x, y)
						this.closeContextMenu()
				},
				closeContextMenu() {
						this.contextMenu.isShow = false
				},
				showContextMenu(e) {
						if (e.preventDefault) e.preventDefault()
						//this.contextMenu.data = this.blocksByType

						this.contextMenu.isShow = true
						this.contextMenu.mouseX = e.x
						this.contextMenu.mouseY = e.y

						this.$nextTick(function () {
								this.setMenu(e.y, e.x)
								this.$refs.contextMenu.focus()
						})
				},
				setMenu(top, left) {
						let border = 5
						let contextMenuEl = this.$refs.contextMenu
						let containerElRect = this.$refs.graph.getBoundingClientRect()
						let largestWidth = containerElRect.right - contextMenuEl.offsetWidth - border
						let largestHeight = containerElRect.bottom - contextMenuEl.offsetHeight - border

						if (left > largestWidth) left = largestWidth
						if (top > largestHeight) top = largestHeight

						this.contextMenu.top = top
						this.contextMenu.left = left
				},
		},
		computed: {
				blocksByType() {
						let blocks = {}
						this.blocksData.forEach(b => {
								if (!blocks[b.family]) blocks[b.family] = []
								blocks[b.family].push(b)
						})
						return blocks
				}
		}
}
export default ContextMenuMixin

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from "./router";
import store from './store'

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import './theme.scss'

Vue.config.productionTip = true
Vue.use(require('vue-moment'));
Vue.use(VueRouter)

import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

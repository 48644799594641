import firebase from 'firebase/app';
import 'firebase/database';    // for realtime database
//import 'firebase/firestore';   // for cloud firestore
import 'firebase/auth';        // for authentication

var firebaseConfig = {
		apiKey: "AIzaSyCHH0dPMP7cVYNyzuK-W5T1sFHX8OKM2Mk",
		authDomain: "thebot-609dc.firebaseapp.com",
		databaseURL: "https://thebot-609dc-default-rtdb.firebaseio.com",
		projectId: "thebot-609dc",
		storageBucket: "thebot-609dc.appspot.com",
		messagingSenderId: "951763788635",
		appId: "1:951763788635:web:1d66ae3745738f06cdb07e"
};

var fire = firebase.initializeApp(firebaseConfig);
export default fire;

import blueprintApi from "@/api/blueprint";
import botApi from "@/api/bot";

const BlueprintDataMixin = {
		data() {
				return {
						blueprint: [],
						blocksData: [],
						bots: [],
				}
		},
		computed: {
				throttle: {
						get: function () {
								if (!this.blueprint.throttle) return [0, 0]
								return this.blueprint.throttle.split(',')
						}
				},
		},
		mounted() {
				this.loadBlocks()
				this.loadUserBots()
				if (this.$route.params.token) {
						this.loadBlueprint(this.$route.params.token)
				} else {
						this.$router.push({name: 'projects'})
				}
		},
		methods: {
				/**
					* Загрузка данных о блоках
					*/
				loadBlocks() {
						blueprintApi.getBlocks().then(resp => {
								this.blocksData = resp.data.blocks
						})
				},

				/**
					* Загрузка пользовательских ботов
					*/
				loadUserBots() {
						botApi.getAll().then(resp => {
								this.bots = resp.data.bots
						})
				},

				/**
					* Загрузка блюпринта проекта
					* @param token {String} токен блюпринта
					*/
				loadBlueprint(token) {
						blueprintApi.get(token, true).then(resp => {
								this.blueprint = resp.data
								this.blocks = this.blueprint.blocks ?? []
								//this.firebaseLink()
						})
				},

				/**
					*  Сохранение данных блюпринта
					* @param data {Object} Данные для сохранения
					*/
				saveBlueprint() {
						this.blueprint.blocks = this.blocks

						blueprintApi.save(this.blueprint.token, this.blueprint).then(() => {
								this.$notify({
										title: 'Проект успешно сохранен'
								})
						})
				},

				/**
					* Throttle time to string
					* @param value {Number}
					*/
				pushThrottleTime(value) {
						this.blueprint.throttle = this.throttle[0] + ',' + value
				},
				/**
					* Throttle count to string
					* @param value {Number}
					*/
				pushThrottleCount(value) {
						this.blueprint.throttle = value + ',' + this.throttle[1]
				},

				/**
					* Активация или деактивация блюпринта
					*/
				switchBlueprintActiveStatus() {
						this.blueprint.active = !this.blueprint.active
						this.saveBlueprint()
				},
		}
};

export default BlueprintDataMixin

<template>
  <div class="w-full h-full bg-gray-100 flex">
    <section
        class="w-full h-full py-5 flex flex-wrap items-baseline content-start px-10 justify-start overflow-y-scroll">
      <BlueprintCard
          class="mr-5 mt-5"
          v-for="bl in blueprints"
          :key="bl.token"
          :blueprint="bl"
          @clickOpen="$router.push({ name: 'editBlueprint', params: { token: bl.token }})"
          @databaseClick="$router.push({ name: 'database', params: { token: bl.token }})"
          @errorsClick="$router.push({ name: 'errors', params: { token: bl.token }})"
          @copyClick="cloneBlueprint(bl.token)"
          @deleteClick="deleteBlueprint(bl.token)"
      />
    </section>

    <project-property-window
        :active-project="project.active"
        :blueprints="blueprints"
        @activate="activateProject"
        @create="createBlueprint"
        @input="update"
        @back="$router.push({name: 'home'})"
    />
  </div>
</template>

<script>
import BlueprintCard from "./Projects/BlueprintCard";
import projectApi from "@/api/project";
import blueprintApi from "@/api/blueprint";
import ProjectPropertyWindow from "@/components/ProjectView/ProjectPropertyWindow";
import Centrifuge from "centrifuge";
import userApi from "@/api/user";

export default {
  name: "Blueprints",
  components: {ProjectPropertyWindow, BlueprintCard},
  data() {
    return {
      blueprints: [],
      project: [],
      newName: '',
      newMethod: '',
      newHTTP: null,
      subscription: null
    }
  },
  mounted() {
    this.loadBlueprints()
    this.centrifugoConnect()
  },
  methods: {
    centrifugoConnect() {

      let projectToken = this.$route.params.token

      //get connection token
      userApi.connectionToken().then(resp => {

        // set default Laravel broadcasting auth path
        let config = {subscribeEndpoint: '/app/broadcasting/auth',
          subscribeHeaders: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }};

        let centrifuge = new Centrifuge('wss://socket.papa-carlo.ru/connection/websocket', config);

        // CONNECTION_TOKEN must be obtained via generateConnectionToken ()
        centrifuge.setToken(resp.data.token);
        let callbacks = {
          "publish": data => {
            this.parseWSData(data.data)
          },
        };
        centrifuge.subscribe("$apicarlo:project." + projectToken, callbacks);

        centrifuge.connect();
      })
    },
    parseWSData(data){
      let event = data.event
      console.log(data, event)
      if (event === 'blueprint.update') {
        this.updateBlueprintData(data.blueprintId, data.paramName, data.paramValue)
      }
    },
    updateBlueprintData(id, paramName, paramValue){
      this.blueprints.forEach(bl => {
        if(bl.id === id)
          bl[paramName] = paramValue
      })
    },
    update(payload) {
      switch (payload.name) {
        case 'name':
          this.newName = payload.value;
          break;
        case 'http':
          this.newHTTP = payload.value;
          break;
        case 'method':
          this.newMethod = payload.value;
          break;
      }
    },
    loadBlueprints() {
      projectApi.get(this.$route.params.token).then(resp => {
        this.blueprints = resp.data.blueprints
        this.project = resp.data
        delete this.project['blueprints'];
      })
    },
    createBlueprint() {
      blueprintApi.create(this.newName, this.newMethod, this.newHTTP, this.project.token)
          .then((resp) => {
            this.blueprints.push(resp.data)
            this.newName = this.newMethod = ''
          })
    },
    cloneBlueprint(token) {
      blueprintApi.clone(token).then(() => {
        this.loadBlueprints()
      })
    },
    deleteBlueprint(token) {
      this.$confirm(
          {
            message: `Are you sure?`,
            button: {no: 'No', yes: 'Yes'},
            callback: confirm => {
              if (confirm) {
                blueprintApi.delete(token).then(() => {
                  this.blueprints = this.blueprints.filter(b => b.token !== token)
                })
              }
            }
          }
      )
    },
    saveProject() {
      projectApi.save(this.project.token, this.project).then(() => {
        this.$notify({
          title: 'Project saved',
          type: 'success'
        })
      })
    },
    activateProject() {
      this.project.active = !this.project.active
      this.saveProject()
    },
    hashCode(str) { // java String#hashCode
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    intToRGB(i) {
      let c = (i & 0x00FFFFFF)
          .toString(16)
          .toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    }
  }
}
</script>

<style lang="scss" scoped>

input, button {
  @apply w-full rounded-full px-5 py-3 mt-5 focus:ring focus-within:outline-none;
}

.card {
  @apply relative bg-gray-600 shadow-lg;
  border-radius: 46px;


  .image {
    @apply bg-red-50;
    border-radius: 42px 42px 0 0;
    width: 355px;
    height: 236px;
  }
}
</style>

<template>
  <div class="graph-view">


    <div class="z-50" v-if="blocksData">
      <blocks-view :blocks-by-type="blocksByType" @select="addBlockFromSidebar"/>
    </div>
    <div class="graph"
         ref="graph">
      <div v-if="blocksData && blueprint">
        <block
            v-for="(block, index) in blocks"
            :key="index"
            :options="blockOptions"
            @select="blockSelect(block)"
            @upInput="changeInputSlotValue"
            @linkingStart="linkingStart(block, $event)"
            @linkingStop="linkingStop(block, $event)"
            @linkingBreak="linkingBreak(block, $event)"
            @delete="blockDelete(block)"
            @blockSelect="this.selectedBlock = block"
            v-bind.sync="block"/>
        <vue-link :lines="lines"/>
      </div>
      <div class="graph--loader" v-else>
        <span>Loading...</span>
      </div>
    </div>
    <div v-if="blueprint" class="z-50">
      <property-window
          v-bind.sync="blueprint"
          @save="saveBlueprint"
          @activate="switchBlueprintActiveStatus"
          @input="propsUpdate"
          @back="$router.push({name: 'project', params: { token: blueprint.project.token }})"
          @selectedUpdate="selectedBlockPropsUpdate"
          :description="description"
          :selected-block="selectedBlock"
          :links-count="links.length"
          :bots="bots"
      />
    </div>
  </div>
</template>

<script>
import Block from "@/components/Block";
import InputEvents from "@/components/GraphView/InputEvents";
import LinkingEvents from "@/components/GraphView/LinkingEvents";
import VueLink from "@/assets/vue-blocks-master/src/components/VueLink";
import ContextMenuMixin from "@/components/GraphView/ContextMenuMixin";
import BlueprintDataMixin from "@/components/GraphView/BlueprintDataMixin";
import FirebaseAutosave from "@/components/GraphView/FirebaseAutosave";
import PropertyWindow from "@/components/GraphView/PropertyWindow";
import BlocksView from "@/components/GraphView/BlocksView";

export default {
  name: "GraphView",
  components: {BlocksView, PropertyWindow, VueLink, Block},
  mixins: [InputEvents, LinkingEvents, ContextMenuMixin, BlueprintDataMixin, FirebaseAutosave],
  data() {
    return {
      //
      targetClassname: 'bl',
      inputSlotClassName: 'iSlt',
      //
      nodes: [],
      blocks: [],
      //
      selectedBlock: null,
      hasDragged: false
    }
  },
  beforeRouteLeave (to, from , next) {
    const answer = window.confirm('Do you really want to leave?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  computed: {
    blockOptions() {
      return {
        width: 200,
        titleHeight: 20,
        scale: this.scale,
        inputSlotClassName: this.inputSlotClassName,
        center: {
          x: this.centerX,
          y: this.centerY
        }
      }
    },
    description() {
      if (this.selectedBlock) {
        let name = this.selectedBlock.name

        return this.blocksData.find(function (block) {
          return block.name === name
        }).description
      }

      return ''
    },
  },
  mounted() {
  },
  methods: {
    //propsUpdate
    propsUpdate(payload){
      if(payload.name === 'throttleCount')
        this.pushThrottleCount(payload.value)
      else if(payload.name === 'throttleTime')
        this.pushThrottleTime(payload.value)
      else
        this.blueprint[payload.name] = payload.value
    },
    selectedBlockPropsUpdate(payload){
      const type = payload.input ? 'inputs' : 'outputs'
      this.selectedBlock[type][payload.index].value = payload.value
    },
    deselectAll(withoutID = null) {
      this.blocks.forEach((value) => {
        if (value.id !== withoutID && value.selected) {
          this.blockDeselect(value)
        }
      })
    },
    // Events
    blockSelect(block) {
      block.selected = true
      this.selectedBlock = block
      this.deselectAll(block.id)
      this.$emit('blockSelect', block)
    },
    blockDeselect(block) {
      block.selected = false

      if (block &&
          this.selectedBlock &&
          this.selectedBlock.id === block.id
      ) {
        this.selectedBlock = null
      }

      this.$emit('blockDeselect', block)
    },
    blockDelete(block) {
      if (block.selected)
        this.blockDeselect(block)

      let linksForRemove = []
      this.links.forEach(l => {
        if (l.originID === block.id || l.targetID === block.id) {
          linksForRemove.push(JSON.parse(JSON.stringify(l)))
        }
      })

      linksForRemove.forEach(l => this.removeLink(l.id))


      this.blocks = this.blocks.filter(b => {
        return b.id !== block.id
      })
    },
    changeInputSlotValue(payload) {
      let type = payload.input ? 'inputs' : 'outputs'
      this.blocks.map((block, index) => {
        if (block.id === payload.blockID) {
          block[type][payload.slotIndex].value = payload.value
          this.updateFirebaseAutosave(block, index)
          //else block.slots.push({value: payload.value, slotID: payload.value})
        }
      })
    },
    //ADD BLOCKS
    copyBlock(){
      if(!this.selectedBlock) return false

      let block = { ...this.selectedBlock }
      block.slots = []
      delete block.id
      delete block.slots

      block.x += 50
      block.y += 50

      this.addNewBlock(block)
    },

    // Blocks
    addNewBlock(bd, x, y) {
      let maxID = Math.max(0, ...this.blocks.map(function (o) {
        return o.id
      }))

      // if x or y not set, place block to center
      if (x === undefined || y === undefined) {
        x = (this.$refs.graph.clientWidth / 2 - this.centerX) / this.scale
        y = (this.$refs.graph.clientHeight / 2 - this.centerY) / this.scale
      } else {
        x = (x - this.centerX) / this.scale
        y = (y - this.centerY) / this.scale
      }

      this.blocks.push(Object.assign({
        id: maxID + 1,
        inputs: JSON.parse(JSON.stringify(bd.inputs)) ?? [],
        outputs: JSON.parse(JSON.stringify(bd.outputs)) ?? [],
        slots: [],
        family: bd.family,
        title: bd.title,
        name: bd.name,
        x,
        y,
        selected: false
      }))

    },

    addBlockFromSidebar(block){
      this.addNewBlock(block)
    },
    //???
    updateScene() {

    }
  }
}
</script>

<style lang="scss">

.graph-view {
  @apply w-full h-full bg-gray-200 relative flex;
  .graph, .graph--loader {
    @apply w-full h-full relative;
  }

  .graph--loader {
    @apply flex items-center text-gray-600 justify-center;
  }
}
</style>

<template>
  <label>
    <span v-if="label">{{ label }}</span>
    <div class="input-container">
      <i v-if="icon">{{ icon }}</i>
      <select :value="value" @input="$emit('input', $event.target.value)" class="w-full">
        <option :value="null">Select option ... </option>
        <option v-for="option in options" :value="option.value" :key="option.value">{{ option.name || option.value }}</option>
      </select>
    </div>
  </label>
</template>

<script>
export default {
  name: "DefaultSelect",
  props: {
    value: {
      required: true
    },
    label: String,
    icon: String,
    placeholder: String,
    type: {
      default: 'text',
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: Array
  },
}
</script>

<style scoped lang="scss">

label {
  @apply block my-2;
  span {
    @apply text-gray-400 font-medium;
  }
}

input, select {
  @apply bg-transparent pr-2 py-2 rounded-none outline-none border-2 border-transparent;
  @apply hover:border-gray-200 focus:border-blue-600 relative;
}

.input-container {
  @apply relative;
  i {
    @apply absolute left-2 top-0.5 py-2;
    @apply font-black text-gray-400;
  }
}

</style>

import api from '@/api/api'

export default {
  get (blueprintToken) {
    return api.call('get', 'errors/' + blueprintToken)
  },
  remove(id) {
    return api.call('delete', 'errors/' + id)
  }
}

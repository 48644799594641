import mouseHelper from "@/assets/vue-blocks-master/src/helpers/mouse";

const InputEvents = {
		data() {
				return {
						dragging: false,
						centerX: 0,
						centerY: 0,
						scale: 1
				}
		},
		mounted() {
				document.documentElement.addEventListener('mousemove', this.handleMove, true)
				document.documentElement.addEventListener('mousedown', this.handleDown, true)
				document.documentElement.addEventListener('mouseup', this.handleUp, true)
				document.documentElement.addEventListener('wheel', this.handleWheel, true)
				document.documentElement.addEventListener('keyup', this.handleKeyUp, true)

				this.centerX = this.$refs.graph.clientWidth / 2
				this.centerY = this.$refs.graph.clientHeight / 2
		},
		beforeDestroy() {
				document.documentElement.removeEventListener('mousemove', this.handleMove, true)
				document.documentElement.removeEventListener('mousedown', this.handleDown, true)
				document.documentElement.removeEventListener('mouseup', this.handleUp, true)
				document.documentElement.removeEventListener('wheel', this.handleWheel, true)
				document.documentElement.removeEventListener('keyup', this.handleKeyUp, true)

		},
		methods: {
				/**
					*
					* @param e {KeyboardEvent}
					*/
				handleKeyUp(e){
						//Shift + D
						if (e.shiftKey && e.code === 'KeyD')
								this.copyBlock()
				},
				/** @param e {MouseEvent} */
				handleMove(e) {
						let mouse = mouseHelper.getMousePosition(this.$refs.graph, e)
						this.mouseX = mouse.x
						this.mouseY = mouse.y

						if (this.dragging) {
								let diffX = this.mouseX - this.lastMouseX
								let diffY = this.mouseY - this.lastMouseY

								this.lastMouseX = this.mouseX
								this.lastMouseY = this.mouseY

								this.centerX += diffX
								this.centerY += diffY

								this.hasDragged = true
						}

						if (this.linking && this.linkStartData) {
								let linkStartPos = this.getConnectionPos(this.linkStartData.block, this.linkStartData.slotNumber, false)
								this.tempLink = {
										x1: linkStartPos.x,
										y1: linkStartPos.y,
										x2: this.mouseX,
										y2: this.mouseY
								}
						}
				},
				handleDown(e) {
						const target = e.target || e.srcElement
						if ((target === this.$refs.graph || target.matches('svg, svg *')) && e.which === 1) {
								this.dragging = true

								let mouse = mouseHelper.getMousePosition(this.$refs.graph, e)
								this.mouseX = mouse.x
								this.mouseY = mouse.y

								this.lastMouseX = this.mouseX
								this.lastMouseY = this.mouseY

								this.deselectAll()
								if (e.preventDefault) e.preventDefault()
						}
				},
				handleUp(e) {
						const target = e.target || e.srcElement

						if (this.dragging) {
								this.dragging = false

								if (this.hasDragged) {
										this.updateScene()
										this.hasDragged = false
								}
						}

						if (this.$refs.graph.contains(target) && (typeof target.className !== 'string' || target.className.indexOf(this.inputSlotClassName) === -1)) {
								this.linking = false
								this.tempLink = null
								this.linkStartData = null
						}
				},
				handleWheel(e) {
						const target = e.target || e.srcElement
						if (this.$refs.graph.contains(target)) {
								//if (e.preventDefault) e.preventDefault()

								let deltaScale = Math.pow(1.1, e.deltaY * -0.01)
								this.scale *= deltaScale

								if (this.scale < this.minScale) {
										this.scale = this.minScale
										return
								} else if (this.scale > this.maxScale) {
										this.scale = this.maxScale
										return
								}

								let zoomingCenter = {
										x: this.mouseX,
										y: this.mouseY
								}

								let deltaOffsetX = (zoomingCenter.x - this.centerX) * (deltaScale - 1)
								let deltaOffsetY = (zoomingCenter.y - this.centerY) * (deltaScale - 1)

								this.centerX -= deltaOffsetX
								this.centerY -= deltaOffsetY

								this.updateScene()
						}
				},
		}
}

export default InputEvents
